import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaUsers, FaAward, FaTwitter, FaLinkedinIn, FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import Ivanphoto from '../assets/images/fellows/Ivan-Kibuuka.jpg'
import AziziPhoto from '../assets/images/fellows/Mujjuzi-Abdulazizi.jpg'
import SaxonPhoto from '../assets/images/fellows/Saxon-Ssekitooleko.jpg'
import JoelPhoto from '../assets/images/fellows/Joel-Sengendo.jpg'
import Mohamedphoto from '../assets/images/fellows/Mohamed-Sharif.jpg'
import LoisePhoto from '../assets/images/fellows/Loise-Machira.jpg'
import Mshidane from '../assets/images/fellows/Mohamed-Shidane.jpg'
import TaongaPhoto from '../assets/images/fellows/Taonga-Chisamanga.jpg'
import VanessaPhoto from '../assets/images/fellows/Vanessa-Nkesha.jpg'
import SameerPhoto from '../assets/images/fellows/Sameer-Luyombo.jpg'
import AristidePhoto from '../assets/images/fellows/Aristide-Kambale.jpg'
import PriscillaPhoto from '../assets/images/fellows/Priscilla-Busulwa.jpg'
import CHristinePhoto from '../assets/images/fellows/Christine-Isimbi.jpg'
import LucyPhoto from '../assets/images/Lucy.png'
import CarlPhoto from '../assets/images/carl.jpg'

const profiles = {
  fellows: [
    {
      name: "IVAN KIBUUKA",
      title: "Founder at Arudem",
      photo: Ivanphoto,
      socials: {
        twitter: "https://twitter.com/ivankibuuka_",
        linkedin: "https://www.linkedin.com/in/ivan-kibuuka/",
        instagram: "https://www.instagram.com/ivan.aru_/",
        facebook: "https://www.facebook.com/ivankibuuka.99/"
      }
    },
    {
      name: "Mujjuzi Azizi",
      title: "Planning Officer, Buganda Kingdom",
      photo: AziziPhoto,
      socials: {
        twitter: "https://x.com/AMujjuzi?t=AfH145tw0m3gZaqTLLHK_g&s=09",
        linkedin: "https://www.linkedin.com/in/mujjuzia",
        instagram: "https://www.instagram.com/mujjuzia?igsh=MW0zejkwbm9sbzd2Mw==",
        facebook: "https://www.facebook.com/share/ZaSYdYXULG7rE2Ne/?mibextid=qi2Omg"
      }
    },
    {
      name: "Saxon Ssekitooleko",
      title: "Drilling & Completions Engineer, TotalEnergies E&P Uganda",
      photo: SaxonPhoto,
      socials: {
        twitter: "https://x.com/____sesa",
        linkedin: "https://www.linkedin.com/in/saxon-ssekitooleko",
        instagram: "https://www.instagram.com/___sesa?igsh=MmhrbWt3OXFnMGs5&utm_source=qr",
        facebook: "https://www.facebook.com/saxon.sesa?mibextid=LQQJ4d"
      }
    },
    {
      name: "Joel Sengendo",
      title: "Management Analyst, Steadman Global Consulting",
      photo: JoelPhoto,
      socials: {}
    },
    {
      name: "Mohamed Sharif",
      title: "Somalia Peacebuilding Programme Advisor, Life and Peace Institute",
      photo: Mohamedphoto,
      socials: {
        twitter: "https://twitter.com/mosharriif",
        linkedin: "https://www.linkedin.com/in/sharrif/",
        instagram: "https://www.instagram.com/mosharriif/",
        facebook: "https://www.facebook.com/msharif44"
      }
    },
    {
      name: "Loise Machira",
      title: "Independent Consultant",
      photo: LoisePhoto,
      socials: {
        twitter: "https://twitter.com/loisemachira",
        linkedin: "https://www.linkedin.com/in/loise-machira-80a09b71/",
        instagram: "https://www.instagram.com/loisemachira/"
      }
    },
    {
      name: "Mohamed Shidane",
      title: "Policy Advisor, Ministry of State for the Presidency, The Presidency, Somalia",
      photo: Mshidane,
      socials: {
        twitter: "https://twitter.com/MIShidane",
        linkedin: "https://www.linkedin.com/in/shidane",
        instagram: "https://www.instagram.com/mo.shidane/",
        facebook: "https://www.facebook.com/MIShidane/"
      }
    },
    {
      name: "Taonga Chisamanga",
      title: "Manager - Governance and Compliance, Bank of Zambia",
      photo: TaongaPhoto,
      socials: {
        linkedin: "https://www.linkedin.com/in/taonga-chisamanga-4b235185"
      }
    },
    {
      name: "Vanessa Nkesha",
      title: "Employer Partnerships Officer, The Bridge Talent Services",
      photo: VanessaPhoto,
      socials: {
        twitter: "https://twitter.com/vanessa_nkesha",
        linkedin: "https://www.linkedin.com/in/vanessa-nkesha-msc-8544aa132"
      }
    },
    {
      name: "Sameer Luyombo",
      title: "Director, Taibah International Schools Ltd.",
      photo: SameerPhoto,
      socials: {}
    },
    {
      name: "Christine Isimbi",
      title: "Associate, Economic Transformation at Tony Blair Institute for Global Change",
      photo: CHristinePhoto,
      socials: {
        linkedin: "https://www.linkedin.com/in/christineisimbi/"
      }
    },
    {
      name: "Naïssa Umutoni Karekezi",
      title: "Business Development Supervisor, African Management Institute",
      photo: null, // No photo provided
      socials: {
        linkedin: "https://www.linkedin.com/in/naissaumutonikarekezi/"
      }
    },
    {
      name: "Aristide Kambale",
      title: "Consultant, Global Resilience Partnership",
      photo: AristidePhoto,
      socials: {
        twitter: "https://twitter.com/KambaleAristide",
        linkedin: "https://www.linkedin.com/in/aristidek/",
        instagram: "https://www.instagram.com/aristidekambale?igsh=MTVmN3R5cnBlZDhtcQ%3D%3D&utm_source=qr",
        facebook: "https://www.facebook.com/angel.aristo"
      }
    },
    {
      name: "Priscilla Busulwa",
      title: "Lawyer and Legal Consultant, Impact Investments",
      photo: PriscillaPhoto,
      socials: {
        linkedin: "https://www.linkedin.com/in/priscillabusulwa/?originalSubdomain=ug"
      }
    }
  ],
  champions: [
    {
      name: "Lucy Mbabazi",
      title: "Managing Director, Better than Cash Alliance",
      photo: LucyPhoto,
    },
    {
      name: "Carl Manlan",
      title: "Vice President, Social Impact for Visa Central and Eastern Europe, Middle East, and Africa.",
      photo: CarlPhoto,
    },
  ]
};

const OurPeople = () => {
  const [activeTab, setActiveTab] = useState("fellows");
  const [visibleCount] = useState(4); // Fixed to always show 4 fellows

  const descriptions = {
    fellows:
      "Our Fellows are leaders driving change in Africa through impactful initiatives and innovative solutions.",
    champions:
      "Our Champion is a remarkable supporter whose contributions empower our mission to create a meaningful impact.",
  };

  // Function to shuffle fellows
  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  return (
    <section className="py-16 bg-gray-100">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="max-w-7xl mx-auto px-6 lg:px-12 space-y-12"
      >
        {/* Title */}
        <h2 className="text-4xl font-bold text-center text-gray-800">
          Meet Our People
        </h2>

        {/* Tabs */}
        <div className="flex justify-center space-x-6">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setActiveTab("fellows")}
            className={`px-8 py-3 rounded-full text-lg font-medium flex items-center space-x-2 transition-all duration-300 ${
              activeTab === "fellows"
                ? "bg-gray-800 text-white shadow-lg"
                : "bg-transparent border-2 border-gray-800 text-gray-800 hover:bg-gray-800 hover:text-white"
            }`}
          >
            <FaUsers size={20} />
            <span>Fellows</span>
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setActiveTab("champions")}
            className={`px-8 py-3 rounded-full text-lg font-medium flex items-center space-x-2 transition-all duration-300 ${
              activeTab === "champions"
                ? "bg-gray-800 text-white shadow-lg"
                : "bg-transparent border-2 border-gray-800 text-gray-800 hover:bg-gray-800 hover:text-white"
            }`}
          >
            <FaAward size={20} />
            <span>Champion</span>
          </motion.button>
        </div>

        {/* Description */}
        <p className="text-center text-lg text-gray-600 max-w-3xl mx-auto">
          {descriptions[activeTab]}
        </p>

        {/* Profiles */}
        {activeTab === "fellows" && (
          <>
            <motion.div
              className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8"
              initial="hidden"
              animate="visible"
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
              }}
            >
              {shuffleArray(profiles.fellows) // Shuffle fellows
                .slice(0, visibleCount) // Only display 4 fellows
                .map((person, index) => (
                  <motion.div
                    key={index}
                    className="group bg-white text-gray-800 rounded-lg p-6 shadow-md hover:shadow-xl transition-shadow duration-300"
                    whileHover={{ scale: 1.03 }}
                  >
                    <div className="flex flex-col items-center">
                      {person.photo ? (
                        <img
                          src={person.photo}
                          alt={person.name}
                          className="w-24 h-24 rounded-full mb-4 transition-transform transform group-hover:scale-110"
                        />
                      ) : (
                        <div className="w-24 h-24 rounded-full mb-4 bg-gray-300 flex items-center justify-center">
                          <span className="text-xl text-gray-600">No Photo</span>
                        </div>
                      )}
                      <h3 className="text-xl font-semibold text-center">
                        {person.name}
                      </h3>
                      <p className="text-sm text-gray-600 text-center">{person.title}</p>
                    </div>
                    <div className="flex justify-center space-x-3 mt-4">
                      {person.socials.twitter && (
                        <a
                          href={person.socials.twitter}
                          className="text-gray-600 hover:text-gray-800"
                          aria-label="Twitter"
                        >
                          <FaTwitter size={20} />
                        </a>
                      )}
                      {person.socials.linkedin && (
                        <a
                          href={person.socials.linkedin}
                          className="text-gray-600 hover:text-gray-800"
                          aria-label="LinkedIn"
                        >
                          <FaLinkedinIn size={20} />
                        </a>
                      )}
                      {person.socials.facebook && (
                        <a
                          href={person.socials.facebook}
                          className="text-gray-600 hover:text-gray-800"
                          aria-label="Facebook"
                        >
                          <FaFacebookF size={20} />
                        </a>
                      )}
                      {person.socials.instagram && (
                        <a
                          href={person.socials.instagram}
                          className="text-gray-600 hover:text-gray-800"
                          aria-label="Instagram"
                        >
                          <FaInstagram size={20} />
                        </a>
                      )}
                    </div>
                  </motion.div>
                ))}
            </motion.div>

            {/* Links to view all fellows and champions */}
            <div className="text-center mt-6 space-x-4">
              <motion.a
                href="/yelp/fellows"
                className="px-8 py-3 text-lg font-medium text-white bg-gray-800 rounded-full shadow-md hover:bg-gray-600 transition-all duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                More ELP Fellows
              </motion.a>
              <motion.a
                href="/huduma/champions"
                className="px-8 py-3 text-lg font-medium text-white bg-gray-800 rounded-full shadow-md hover:bg-gray-600 transition-all duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                More Huduma Champions
              </motion.a>
            </div>
          </>
        )}

        {activeTab === "champions" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="grid grid-cols-1 lg:grid-cols-2 gap-12 mt-12"
          >
            {profiles.champions.map((champion, index) => (
              <motion.div
                key={champion.name}
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ 
                  duration: 0.5,
                  delay: index * 0.2,
                  ease: "easeOut"
                }}
                className="group relative overflow-hidden rounded-2xl bg-white shadow-xl hover:shadow-2xl transition-all duration-300"
              >
                <div className="flex flex-col md:flex-row h-full">
                  {/* Image Container */}
                  <div className="md:w-2/5 relative overflow-hidden">
                    <motion.img
                      src={champion.photo}
                      alt={champion.name}
                      className="w-full h-72 md:h-full object-cover object-center transform group-hover:scale-110 transition-transform duration-700"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  </div>

                  {/* Content Container */}
                  <div className="md:w-3/5 p-8 flex flex-col justify-center relative">
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.3 }}
                    >
                      <h3 className="text-2xl font-bold text-gray-900 mb-3 group-hover:text-blue-600 transition-colors duration-300">
                        {champion.name}
                      </h3>
                      <p className="text-gray-600 text-lg leading-relaxed mb-6">
                        {champion.title}
                      </p>

                      {/* Social Links */}
                      {champion.socials && (
                        <motion.div 
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.5 }}
                          className="flex space-x-4"
                        >
                          {champion.socials.linkedin && (
                            <a
                              href={champion.socials.linkedin}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="p-2 rounded-full bg-gray-100 hover:bg-blue-600 hover:text-white transition-all duration-300"
                            >
                              <FaLinkedin className="w-5 h-5" />
                            </a>
                          )}
                        </motion.div>
                      )}
                    </motion.div>

                    {/* Decorative Element */}
                    <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-blue-500/10 to-transparent rounded-full -translate-y-1/2 translate-x-1/2" />
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        )}
      </motion.div>
    </section>
  );
};

export { profiles };
export default OurPeople;
