exports.components = {
  "component---src-pages-2020-alg-js": () => import("./../../../src/pages/2020-alg.js" /* webpackChunkName: "component---src-pages-2020-alg-js" */),
  "component---src-pages-2021-alg-js": () => import("./../../../src/pages/2021-alg.js" /* webpackChunkName: "component---src-pages-2021-alg-js" */),
  "component---src-pages-2022-alg-js": () => import("./../../../src/pages/2022-alg.js" /* webpackChunkName: "component---src-pages-2022-alg-js" */),
  "component---src-pages-2023-alg-js": () => import("./../../../src/pages/2023-alg.js" /* webpackChunkName: "component---src-pages-2023-alg-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-champions-js": () => import("./../../../src/pages/about/champions.js" /* webpackChunkName: "component---src-pages-about-champions-js" */),
  "component---src-pages-about-faqs-js": () => import("./../../../src/pages/about/faqs.js" /* webpackChunkName: "component---src-pages-about-faqs-js" */),
  "component---src-pages-about-history-and-mission-js": () => import("./../../../src/pages/about/history-and-mission.js" /* webpackChunkName: "component---src-pages-about-history-and-mission-js" */),
  "component---src-pages-about-history-js": () => import("./../../../src/pages/about/history.js" /* webpackChunkName: "component---src-pages-about-history-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-mission-vision-js": () => import("./../../../src/pages/about/mission-vision.js" /* webpackChunkName: "component---src-pages-about-mission-vision-js" */),
  "component---src-pages-about-pillars-of-action-js": () => import("./../../../src/pages/about/pillars-of-action.js" /* webpackChunkName: "component---src-pages-about-pillars-of-action-js" */),
  "component---src-pages-about-team-awel-uwihanganye-js": () => import("./../../../src/pages/about/team/awel-uwihanganye.js" /* webpackChunkName: "component---src-pages-about-team-awel-uwihanganye-js" */),
  "component---src-pages-about-team-brian-turyabagye-js": () => import("./../../../src/pages/about/team/brian-turyabagye.js" /* webpackChunkName: "component---src-pages-about-team-brian-turyabagye-js" */),
  "component---src-pages-about-team-js": () => import("./../../../src/pages/about/team.js" /* webpackChunkName: "component---src-pages-about-team-js" */),
  "component---src-pages-about-team-kevin-langley-js": () => import("./../../../src/pages/about/team/kevin-langley.js" /* webpackChunkName: "component---src-pages-about-team-kevin-langley-js" */),
  "component---src-pages-about-team-magnus-mchunguzi-js": () => import("./../../../src/pages/about/team/magnus-mchunguzi.js" /* webpackChunkName: "component---src-pages-about-team-magnus-mchunguzi-js" */),
  "component---src-pages-about-team-nanda-kato-js": () => import("./../../../src/pages/about/team/nanda-kato.js" /* webpackChunkName: "component---src-pages-about-team-nanda-kato-js" */),
  "component---src-pages-about-team-nelson-asimwe-js": () => import("./../../../src/pages/about/team/nelson-asimwe.js" /* webpackChunkName: "component---src-pages-about-team-nelson-asimwe-js" */),
  "component---src-pages-about-team-nnanda-kizito-sseruwagi-js": () => import("./../../../src/pages/about/team/nnanda-kizito-sseruwagi.js" /* webpackChunkName: "component---src-pages-about-team-nnanda-kizito-sseruwagi-js" */),
  "component---src-pages-about-team-rosie-lorie-js": () => import("./../../../src/pages/about/team/rosie-lorie.js" /* webpackChunkName: "component---src-pages-about-team-rosie-lorie-js" */),
  "component---src-pages-about-team-william-babigumira-js": () => import("./../../../src/pages/about/team/william-babigumira.js" /* webpackChunkName: "component---src-pages-about-team-william-babigumira-js" */),
  "component---src-pages-about-team-zubeda-abdallah-js": () => import("./../../../src/pages/about/team/zubeda-abdallah.js" /* webpackChunkName: "component---src-pages-about-team-zubeda-abdallah-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-editions-js": () => import("./../../../src/pages/editions.js" /* webpackChunkName: "component---src-pages-editions-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-fellows-and-champions-js": () => import("./../../../src/pages/fellows-and-champions.js" /* webpackChunkName: "component---src-pages-fellows-and-champions-js" */),
  "component---src-pages-huduma-about-js": () => import("./../../../src/pages/huduma/about.js" /* webpackChunkName: "component---src-pages-huduma-about-js" */),
  "component---src-pages-huduma-champions-js": () => import("./../../../src/pages/huduma/champions.js" /* webpackChunkName: "component---src-pages-huduma-champions-js" */),
  "component---src-pages-huduma-index-js": () => import("./../../../src/pages/huduma/index.js" /* webpackChunkName: "component---src-pages-huduma-index-js" */),
  "component---src-pages-impact-js": () => import("./../../../src/pages/impact.js" /* webpackChunkName: "component---src-pages-impact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-initiatives-js": () => import("./../../../src/pages/initiatives.js" /* webpackChunkName: "component---src-pages-initiatives-js" */),
  "component---src-pages-media-resource-js": () => import("./../../../src/pages/media-resource.js" /* webpackChunkName: "component---src-pages-media-resource-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-partner-with-us-js": () => import("./../../../src/pages/partner-with-us.js" /* webpackChunkName: "component---src-pages-partner-with-us-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-register-townhall-js": () => import("./../../../src/pages/register-townhall.js" /* webpackChunkName: "component---src-pages-register-townhall-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/searchResults.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-yelp-about-js": () => import("./../../../src/pages/yelp/about.js" /* webpackChunkName: "component---src-pages-yelp-about-js" */),
  "component---src-pages-yelp-fellows-js": () => import("./../../../src/pages/yelp/fellows.js" /* webpackChunkName: "component---src-pages-yelp-fellows-js" */),
  "component---src-pages-yelp-index-js": () => import("./../../../src/pages/yelp/index.js" /* webpackChunkName: "component---src-pages-yelp-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-publication-js": () => import("./../../../src/templates/publication.js" /* webpackChunkName: "component---src-templates-publication-js" */)
}

