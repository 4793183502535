import React, { useState } from 'react';
import { FiMail, FiArrowRight, FiUser } from 'react-icons/fi';
import { collection, addDoc } from 'firebase/firestore';
import db from '../firebaseConfig';

const SubscriptionForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: ''
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      await addDoc(collection(db, 'subscribers'), formData);
      setMessage('Thank you for subscribing!');
      setFormData({ name: '', email: '' });
    } catch (error) {
      console.error('Error adding subscriber to Firestore:', error);
      setMessage('There was an error. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="w-full bg-gradient-to-br from-[#0B9A9E] via-[#0B9A9E]/90 to-[#0B9A9E]/80 py-16">
      <div className="max-w-6xl mx-auto px-8 text-white space-y-8">
        {/* Title */}
        <h2 className="text-4xl font-extrabold tracking-tight text-center">
          Join the Movement
        </h2>

        {/* Description */}
        <p className="text-lg leading-relaxed max-w-3xl mx-auto text-center text-white/90">
          Be part of a transformative community shaping Africa's future. Join our network of emerging leaders and stay updated with the latest opportunities, events, and insights.
        </p>

        {/* Subscription Form */}
        <form 
          onSubmit={handleSubmit} 
          className="flex flex-col space-y-4 w-full max-w-2xl mx-auto bg-white/10 backdrop-blur-sm p-8 rounded-2xl shadow-xl"
        >
          {/* Name Input */}
          <div className="flex items-center bg-white rounded-xl shadow-inner p-4 group focus-within:ring-2 focus-within:ring-[#0B9A9E] transition-all duration-300">
            <FiUser className="text-[#0B9A9E] mr-3 w-5 h-5" />
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your full name"
              className="w-full bg-transparent text-gray-800 focus:outline-none text-lg"
              required
            />
          </div>

          {/* Email Input */}
          <div className="flex items-center bg-white rounded-xl shadow-inner p-4 group focus-within:ring-2 focus-within:ring-[#0B9A9E] transition-all duration-300">
            <FiMail className="text-[#0B9A9E] mr-3 w-5 h-5" />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your email address"
              className="w-full bg-transparent text-gray-800 focus:outline-none text-lg"
              required
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading}
            className={`group bg-white text-[#0B9A9E] font-semibold py-4 px-8 rounded-xl transition-all duration-300 hover:bg-[#0B9A9E] hover:text-white shadow-lg transform ${
              loading ? 'opacity-50 cursor-not-allowed' : 'hover:scale-102'
            } flex items-center justify-center mt-4`}
          >
            {loading ? 'Joining...' : 'Join Now'}
            <FiArrowRight 
              className={`ml-2 transition-transform duration-300 transform ${
                loading ? '' : 'group-hover:translate-x-1'
              }`} 
            />
          </button>
        </form>

        {/* Feedback Message */}
        {message && (
          <div className={`text-center p-4 rounded-xl ${
            message.includes('error') ? 'bg-red-500/10' : 'bg-white/10'
          } backdrop-blur-sm max-w-2xl mx-auto`}>
            <p className="text-lg font-semibold">
              {message}
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default SubscriptionForm;
